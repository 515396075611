<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane
      v-for="(route, index) in routes"
      :key="index"
      :label="route.meta.title"
      :name="route.path"
      lazy
    >
      <router-view />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
// import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter';

export default {
  data: () => ({
    activeName: 'info',
    routes: [],
  }),
  watch: {
    $route(to) {
      this.activeName = to.name;
    },
  },
  mounted() {
    this.activeName = this.$route.name;
    const pathParent = `/${this.$route.path.split('/')[1]}/:id`;
    // const name = this.$route.path.split('/')[2];
    const findRoute = this.$router.options.routes.find((v) => v.path === pathParent);
    // const findChildren = findRoute.children.find((v) => v.name === name);
    this.routes = findRoute.children;
  },
  methods: {
    handleClick(event) {
      const path = event.$options.propsData.name;
      this.$router.push(`${path}`);
      this.activeName = path;
    },
  },
};
</script>
